import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserInvitation from '../../models/UserInvitation';
import PlatformService from '../../services/PlatformService';
import { isEmailValid } from '../../utils/EmailUtils';
import { Option } from '../Option';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Input } from '../shared/form-control/Input';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import usePermissions from '../../hooks/permissions/usePermissions';
import AdminService from '../../services/AdminService';
import LanguageSelector from '../shared/LanguageSelector';
import { systemDefaultLanguageCode } from '../../types/Languages';
import { Roles } from '../../models/Role';
import { ClientFormUserRoleValues } from '../../models/ClientFormUserRoles';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';

type InviteOrSearchContentProps = {
  exisitingUsers?: Option<string, string>[];
  formRoles?: Option<string, string | number>[];
  selectedFormRole?: Option<string, string | number>;
  inviteMode: boolean;
  filterRoles: (role: Option<string, string>) => boolean;
  onFormRoleChange?: (formRole: Option<string, string | number>) => void;
  onInputsValid: (isValid: boolean) => void;
  onUserChange: (user: UserInvitation) => void;
  clientId: string | null | undefined;
};

const EMPTY_USER: UserInvitation = {
  emailAddress: '',
  role: Roles.Employee,
  firstname: '',
  surname: '',
  clientId: '',
  clientName: '',
  languageCode: systemDefaultLanguageCode,
};

const InviteOrSearchContent: FC<InviteOrSearchContentProps> = (props) => {
  const { exisitingUsers, filterRoles, inviteMode, onInputsValid, onUserChange, selectedFormRole, formRoles, onFormRoleChange, clientId } = props;
  const { t, i18n } = useTranslation('common');
  const [platformRoles, setPlatformRoles] = useState<Option<string, string>[]>([]);
  const [inviteUser, setInviteUser] = useState({ ...EMPTY_USER, languageCode: i18n.language });
  const [addUser, setAddUser] = useState({ ...EMPTY_USER, languageCode: i18n.language });
  const [addSearch, setAddSearch] = useState('');
  const [clients, setClients] = useState<Option<string, string>[]>([]);
  const hasPermission = usePermissions();
  const currentClient = useRecoilValue(currentClientAtom);

  useEffect(() => {
    PlatformService.getRoles().then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setPlatformRoles(res.data.map((role) => ({ id: role, text: t(`roles.${role}` as any), value: role })).filter(filterRoles));
    });
  }, [filterRoles, t]);

  useEffect(() => {
    if (!clientId) {
      AdminService.getAccounts({ pageSize: 1000 }).then((res) => {
        setClients(res.data.map((account) => ({ id: account.id, text: account.name, value: account.id })));
      });
    }
  }, [clientId]);

  useEffect(() => {
    setInviteUser({ ...EMPTY_USER, languageCode: currentClient?.language || i18n.language });
    setAddUser({ ...EMPTY_USER, languageCode: currentClient?.language || i18n.language });
    setAddSearch('');
  }, [currentClient?.language, i18n.language, inviteMode]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onInviteUserChange = useCallback((key: keyof typeof inviteUser, value: any) => {
    setInviteUser((prev) => ({ ...prev, [key]: value }));
  }, []);

  useEffect(() => {
    onInviteUserChange('clientId', clientId || '');
  }, [clientId, onInviteUserChange]);

  const invitePermissionsOptions = useMemo(() => {
    if (!platformRoles.length) {
      return [];
    }
    return platformRoles.filter((x) => filterRoles(x) && hasPermission(x.value)).sort((a, b) => a.text.localeCompare(b.text));
  }, [filterRoles, hasPermission, platformRoles]);

  const addOnPick = useCallback(
    (option: Option<string, string | number>) => {
      setAddSearch(option.text);

      // first & last name in firstName field because we can't split it easily. Server will have the correct values
      // Role isn't used when associating a user with a client
      setAddUser({
        id: option.id,
        emailAddress: option.value as string,
        firstname: option.text,
        role: '',
        clientId: clientId || '',
        languageCode: currentClient?.language || systemDefaultLanguageCode,
      });
    },
    [clientId, currentClient?.language],
  );

  useEffect(() => {
    if (inviteMode) {
      const isClientValid = (!clientId && !!inviteUser.clientId) || !!clientId;
      onInputsValid(!!inviteUser.firstname && !!inviteUser.surname && !!isEmailValid(inviteUser.emailAddress) && !!inviteUser.role && isClientValid);
    } else {
      return onInputsValid(!!addUser.id);
    }
  }, [
    clientId,
    addUser.id,
    inviteMode,
    inviteUser.clientId,
    inviteUser.emailAddress,
    inviteUser.firstname,
    inviteUser.role,
    inviteUser.surname,
    onInputsValid,
  ]);

  useEffect(() => {
    onUserChange(inviteMode ? inviteUser : addUser);
  }, [addUser, inviteUser, inviteMode, onUserChange]);

  return inviteMode ? (
    <div data-cy="invite-user">
      <Input
        data-cy="first-name"
        value={inviteUser.firstname}
        onChange={(e) => onInviteUserChange('firstname', e.target.value)}
        placeholder={t('add-or-invite-modal.invite.first-name')}
        label={t('add-or-invite-modal.invite.first-name')}
        maxLength={50}
      />

      <Input
        data-cy="last-name"
        value={inviteUser.surname}
        onChange={(e) => onInviteUserChange('surname', e.target.value)}
        placeholder={t('add-or-invite-modal.invite.last-name')}
        label={t('add-or-invite-modal.invite.last-name')}
        maxLength={50}
      />

      <Input
        data-cy="email"
        value={inviteUser.emailAddress}
        onChange={(e) => onInviteUserChange('emailAddress', e.target.value)}
        placeholder={t('add-or-invite-modal.invite.email')}
        label={t('add-or-invite-modal.invite.email')}
        maxLength={100}
      />

      {!clientId && (
        <DropdownSelect
          data-cy="platform-permission"
          wrapperClassName="mt-8 w-full"
          placeholder={t('add-or-invite-modal.invite.organisation')}
          label={t('add-or-invite-modal.invite.organisation')}
          aria-label={t('add-or-invite-modal.invite.organisation')}
          options={clients}
          onChange={(o) => {
            onInviteUserChange('clientId', o.value as string);
            onInviteUserChange('clientName', o.text as string);
          }}
          value={{
            id: inviteUser.clientId || '',
            value: inviteUser.clientId || '',
            text: clients.find((x) => x.value === inviteUser.clientId)?.text || '',
          }}
        />
      )}

      <DropdownSelect
        data-cy="platform-permission"
        wrapperClassName="mt-8 w-full"
        placeholder={t('add-or-invite-modal.invite.permission')}
        label={t('add-or-invite-modal.invite.permission')}
        aria-label={t('add-or-invite-modal.invite.permission')}
        options={invitePermissionsOptions}
        onChange={(o) => onInviteUserChange('role', o.value as string)}
        value={{
          id: inviteUser.role,
          value: inviteUser.role,
          text: invitePermissionsOptions.find((x) => x.value === inviteUser.role)?.text || '',
        }}
      />

      {formRoles && (
        <DropdownSelect
          wrapperClassName="mt-8 w-full"
          data-cy="form-permission"
          value={selectedFormRole}
          onChange={(data) => {
            onFormRoleChange && onFormRoleChange(data);
            onInviteUserChange('defaultClientFormRole', data.value as ClientFormUserRoleValues);
          }}
          options={formRoles}
          placeholder={t('add-or-invite-modal.form-role-placeholder')}
          label={t('add-or-invite-modal.form-role-placeholder')}
          aria-label={t('add-or-invite-modal.form-role-placeholder')}
          disabled={formRoles.length <= 1}
        ></DropdownSelect>
      )}

      <div className="mt-8 w-full">
        <LanguageSelector
          setCurrentUserLanguage={false}
          onLanguageChange={(code) => onInviteUserChange('languageCode', code)}
          label={t('add-or-invite-modal.invite.language')}
          value={inviteUser.languageCode}
        />
      </div>
    </div>
  ) : (
    <div data-cy="add-user">
      {!!exisitingUsers && (
        <div className="flex flex-col gap-4">
          <InputSuggestion
            data-cy="user-search"
            inputConfig={{
              value: addSearch,
              placeholder: t('add-or-invite-modal.add.search'),
            }}
            onChange={setAddSearch}
            onPick={addOnPick}
            suggestions={exisitingUsers}
          />

          {formRoles && (
            <DropdownSelect
              wrapperClassName="mt-8 w-full"
              data-cy="form-permission"
              value={selectedFormRole}
              onChange={(data) => onFormRoleChange && onFormRoleChange(data)}
              options={formRoles}
              placeholder={t('add-or-invite-modal.form-role-placeholder')}
              label={t('add-or-invite-modal.form-role-placeholder')}
              aria-label={t('add-or-invite-modal.form-role-placeholder')}
              disabled={formRoles.length <= 1}
            ></DropdownSelect>
          )}
        </div>
      )}
    </div>
  );
};

export default InviteOrSearchContent;
